import { Injectable } from '@angular/core';
import { MIMEType } from '@lib-core/enums';
import { download } from '@lib-core/helpers';
import { ImagesResource } from '@lib-core/resources/images.resource';

@Injectable({
	providedIn: 'root',
})
export class ImagesService {
	constructor(private imagesResource: ImagesResource) {
	}

	downloadImagesZip(array: any[], orderCode: string, isAllColorsFromOrder: boolean): Promise<void> {
		return this.imagesResource.downloadImagesZip(array, orderCode, isAllColorsFromOrder)
			.then(response => {
				download(response, 'imagens', MIMEType.Zip);
			});
	}

	sendMailWithSalesOrderCopy(erpSalesOrderCode: string) {
		return this.imagesResource.sendMailWithSalesOrderCopy(erpSalesOrderCode);
	}
}
