import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { GalleryFilterComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter/gallery-filter.component';

@Component({
	standalone: true,
	selector: 'app-gallery-content',
	imports: [RouterOutlet, GalleryFilterComponent],
	templateUrl: './gallery-content.component.html',
})
export class GalleryContentComponent {}
