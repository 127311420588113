<div
	[class]="classList"
	[style.height]="containerHeight"
	class="d-flex justify-content-center align-items-center"
>
	<img
		[alt]="alt"
		*ngIf="!isZoomEnabled"
		[src]="imageUrl"
		[style.height]="imageHeight"
		[style.width]="imageWidth"
	/>
	<img
		[alt]="alt"
		*ngIf="isZoomEnabled"
		[src]="imageUrl"
		[redZoom]="imageUrl"
		redZoomClass="red-zoom--style--overlay"
		[style.width]="imageWidth"
		[style.height]="imageHeight"
	/>
</div>
