import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { ProductModel } from '@lib-core/models';
import { ENVIRONMENT } from '../../../../../../../../../environments/environment';

const TABLE_MEASURES_MAP = {
	enfim: {
		active: {
			'1': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.ManActiveEnfim,
			'2': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.WomanActiveEnfim,
		},
		casual: {
			'1': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.ManCasualEnfim,
			'2': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.WomanCasualEnfim,
		},
	},
	jeans: {
		'1': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.ManAdultJeans,
		'2': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.WomanAdultJeans,
	},
	kids: {
		'1': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.ManKids,
		'2': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.WomanKids,
	},
	plus: {
		'1': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.ManPlus,
		'2': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.WomanPlus,
	},
	adults: {
		'1': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.ManAdults,
		'2': ENVIRONMENT.backOfficeSettings.measureTableImageUrls.WomanAdults,
	},
};

@Component({
	selector: 'app-product-details-tab',
	standalone: true,
	templateUrl: './product-details-tab.component.html',
	imports: [CommonModule, NgbNavModule, NgbModule],
})
export class ProductDetailsTabComponent {
	active = 1;

	@Input()
	product: ProductModel;

	getMeasureTableImage(): string {
		if (this.isEnfim()) {
			return this.getEnfimMap();
		}

		if (this.isJeansCategory()) {
			return this.getJeansCategoryMap();
		}

		return this.getSegmentMap();
	}

	isEnfim(): boolean {
		return this.product.brandCode === '07';
	}

	getEnfimMap(): string | null {
		return TABLE_MEASURES_MAP.enfim[this.product.productGroup === '00002' ? 'active' : 'casual'][this.product.genderCode];
	}

	isJeansCategory(): boolean {
		return this.product.categoryCode === '05';
	}

	getJeansCategoryMap(): string {
		return TABLE_MEASURES_MAP.jeans[this.product.genderCode];
	}

	getSegmentMap(): string {
		const segmentKey = this.product.segmentCode === '3' ? 'plus' : this.product.segmentCode !== '1' ? 'kids' : 'adults';
		return TABLE_MEASURES_MAP[segmentKey][this.product.genderCode] || null;
	}
}
