import { Injectable } from '@angular/core';
import { HttpResource } from '@lib-core/resources';
import { ApiResponseModel, ProductModel } from '@lib-core/models';
import { GalleryRequestDto, GalleryResponseDto } from '@lib-core/dtos';

@Injectable({
	providedIn: 'root',
})
export class GalleryResource {
	private baseUrl = 'Gallery';

	constructor(private httpResource: HttpResource) {}

	queryFilter(filter: GalleryRequestDto) {
		return this.httpResource.post<ApiResponseModel<GalleryResponseDto>>(`${this.baseUrl}/Query`, filter, {
			showLoader: true,
			loaderMessage: 'Buscando grade de produtos',
		});
	}

	getProductDetails(productCode: string, modalityCode: string, salesTeamCode: string, customerCode: string) {
		return this.httpResource.post<ApiResponseModel<ProductModel>>(
			`${this.baseUrl}/GetProduct/${productCode}`,
			{
				modalityCode,
				salesTeamCode,
				customerCode,
			},
			{
				showLoader: true,
				loaderMessage: 'Carregando detalhes dos produtos',
			},
		);
	}
}
