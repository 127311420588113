export class CartItemRequestDto {
	cartId: number;
	quantity: number;
	productCode: string;
	sizeCode: string;
	colorCode: string;
	gradeCode: string;

	constructor() {
		this.cartId = 0;
		this.productCode = '';
		this.sizeCode = '';
		this.colorCode = '';
		this.gradeCode = '';
		this.quantity = 0;
	}
}
