import { CommonModule } from '@angular/common';
import { RedZoomModule } from 'ngx-red-zoom';
import { Component, Input } from '@angular/core';

import { ImageModel } from '@lib-core/models';
import { isStringNullOrEmpty } from '@lib-core/helpers';

const EMPTY_IMAGE_URL = './assets/images/sem-foto.png';

@Component({
	standalone: true,
	selector: 'app-product-image, [app-product-image]',
	imports: [CommonModule, RedZoomModule],
	templateUrl: './product-image.component.html',
})
export class ProductImageComponent {
	@Input()
	url: string;

	@Input()
	imageList: ImageModel[];

	@Input()
	height: string | number = 300;

	@Input()
	width: string | number;

	@Input()
	classList: string = '';

	@Input()
	alt: string = 'Imagem não disponível';

	@Input()
	enableZoom: boolean = false;

	get containerHeight(): string {
		return typeof this.height === 'string' ? this.height : `${this.height}px`;
	}

	get imageHeight(): string {
		return this.isEmptyImage ? 'auto' : this.containerHeight;
	}

	get containerWidth(): string {
		return typeof this.width === 'string' ? this.width : `${this.width}px`;
	}

	get imageWidth(): string {
		return this.isEmptyImage ? 'auto' : this.containerWidth;
	}

	get hasImage(): boolean {
		return !isStringNullOrEmpty(this.imageUrl) && this.imageUrl !== EMPTY_IMAGE_URL;
	}

	get imageUrl(): string {
		if (!isStringNullOrEmpty(this.url)) {
			return this.url;
		}

		if (
			this.imageList &&
			this.imageList.length > 0 &&
			!isStringNullOrEmpty(this.imageList[0].url)
		) {
			return this.imageList[0].url;
		}

		return EMPTY_IMAGE_URL;
	}

	get isZoomEnabled() {
		return this.enableZoom && this.hasImage;
	}

	get isEmptyImage() {
		return this.imageUrl === EMPTY_IMAGE_URL;
	}
}
