
<div class="container-xxl">
	<div *ngIf="!hasProducts">
		<app-cart-empty />
	</div>
	<div *ngIf="orderSent">
		<app-cart-ordered-successfully [erpSalesOrderCode]="cart.erpSalesOrderCode" />
	</div>

	<div *ngIf="hasProducts && paymentCondition && !orderSent">
		<shared-page-title title="Sacola de compras" />
		<form novalidate="" class="needs-validation" [formGroup]="cartForm" (ngSubmit)="processCart()">
			<div class="row mb-4">
				<div class="col-24 col-sm-12 col-lg-12 col-xl-9">
					<label for="paymentConditionCode" class="form-label">Condição de pagamento</label>
					<select
						class="form-select"
						name="paymentConditionCode"
						(change)="processCart()"
						formControlName="paymentConditionCode"
						[ngClass]="{ 'is-invalid': submitted && f.paymentConditionCode.errors }">
						@for (condition of paymentCondition.allowedPaymentConditions; track condition.code) {
							<option [value]="condition.code">
								{{ condition.description | uppercase }}
							</option>
						}
					</select>
					<div class="invalid-feedback">Informe uma condição de pagamento</div>
				</div>
				<div class="col-24 col-sm-12 col-lg-12 col-xl-9">
					<label for="numberOfAdditionalDaysForPayment" class="form-label">Prazo Extra</label>
					<input
						type="number"
						class="form-control"
						(change)="processCart()"
						name="numberOfAdditionalDaysForPayment"
						formControlName="numberOfAdditionalDaysForPayment"
						[ngClass]="{ 'is-invalid': submitted && f.numberOfAdditionalDaysForPayment.errors }" />
					<div class="invalid-feedback">
						<span *ngIf="f.numberOfAdditionalDaysForPayment.errors?.min"> O prazo extra deve ser maior que {{ this.paymentCondition.minExtra }} </span>
						<span *ngIf="f.numberOfAdditionalDaysForPayment.errors?.max"> O prazo extra deve ser menor que {{ this.paymentCondition.maxExtra }} </span>
					</div>
				</div>
			</div>
		</form>
		<div class="row mb-4">
			<div class="col-24 col-lg-24 col-xl-18">
				@for (item of cart.distinctCartItemList; track item.id) {
					<div>
						<core-group-box [classList]="'mb-4'">
							<ng-template #header>
								<input class="form-check-input m-0 me-2" type="checkbox" [id]="item.productCode" />
								<label class="form-check-label text-truncate" [for]="item.productCode">
									{{ getTitle(item) }}
								</label>
							</ng-template>
							<ng-template #body>
								<div class="">
									<div class="row m-0 align-items-center">
										<div class="col-24 col-lg-6 text-center">
											<app-product-image [height]="200" [url]="getImageUrl(item)" />
										</div>
										<div class="col-24 col-lg-18">
											<app-cart-product-item-table [productCode]="item.productCode" [cart]="cart" />
										</div>
									</div>
									<div class="alert alert-danger" *ngIf="showItemMessage(item)">
										{{ item.message }}
									</div>
									<div class="d-flex justify-content-end">
										<button class="btn btn-secondary me-2" ngbTooltip="Editar produto" (click)="onEdit(item)" *ngIf="isItemValid(item)">
											<i class="bi bi-pencil"></i> Editar
										</button>
										<button class="btn btn-danger" ngbTooltip="Remover da sacola" (click)="onRemove(item)"><i class="bi bi-trash"></i> Remover</button>
									</div>
								</div>
							</ng-template>
						</core-group-box>
					</div>
				}
			</div>
			<div class="col-24 col-lg-24 col-xl-6">
				<div ngbAccordion>
					<div ngbAccordionItem [collapsed]="false">
						<h2 ngbAccordionHeader>
							<button ngbAccordionButton>Valores</button>
						</h2>
						<div ngbAccordionCollapse>
							<div ngbAccordionBody>
								<ng-template>
									<core-label-value
										label="Itens Totais"
										value="{{ getTotalItems() }}"
										[singleLine]="true"
										classList="justify-content-between"></core-label-value>
									<core-label-value
										label="Total Bruto"
										value="{{ cart.grossValue | brl }}"
										[singleLine]="true"
										classList="justify-content-between"></core-label-value>
									<core-label-value
										label="Descontos"
										value="{{ getDiscountTotal() | brl }}"
										[singleLine]="true"
										classList="justify-content-between"></core-label-value>
									@for (discount of cart.appliedDiscountList; track discount.code) {
										<div class="d-flex justify-content-between">
											<small>{{ getDiscountDescription(discount) | uppercase }}</small>
											<small>{{ discount.percent | percentFormat }}</small>
										</div>
									}
									<hr />
									<core-label-value
										label="Valor Total"
										value="{{ cart.netValue | brl }}"
										[singleLine]="true"
										classList="justify-content-between mb-3"></core-label-value>
									<div class="alert" class="alert alert-danger" *ngIf="error">{{ error }}</div>
									<button class="btn btn-success w-100" (click)="sendToErp(cart)" [disabled]="!isCartValid">
										<i class="bi bi-cash-stack"></i>
										Finalizar pedido
									</button>
									<!-- Botão teste para limpar o carrinho, não habilitar por enquanto -->
									<!-- <button class="btn btn-danger mt-3 w-100" (click)="onRemoveCart()">Limpar carrinho</button> -->
									<a class="btn btn-link mt-3 w-100" [routerLink]="galleryRoutes.list.url">
										<i class="bi bi-arrow-left"></i>
										Voltar para a galeria
									</a>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
