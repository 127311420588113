<!-- COMPONENTE SERÁ EXCLUÍDO QUANDO VIRAR 100% DO PORTAL -->
<div class="content text-center" [ngClass]="{ show: isVisible }">
	<div class="position-absolute end-0">
		<button class="btn btn-sm text-secondary" (click)="close()">
			<i class="bi bi-x-lg"></i>
		</button>
	</div>
	<div class="px-3 py-4 border-bottom border-secondary">
		<img alt="Malwee" src="./assets/images/logo-inversed.svg" width="200" />
	</div>
	<div class="p-3">
		<a [href]="portalUrl" target="_self" class="link-secondary">Voltar para o portal</a>
	</div>
</div>

<div class="overlay" [ngClass]="{ show: isVisible }" (click)="close()" (keyup)="close()"></div>
