<div class="product-details" *ngIf="product">
	<div class="row">
		<div class="offset-xxl-10 offset-xl-12 col-24">
			<h3>{{ product.description }}</h3>
			<p>Código: {{ product.code }}</p>
		</div>
	</div>
	<div class="row">
		<div class="col-xl-12 col-xxl-10 col-24 mb-4">
			<div class="row">
				<div class="col-24 col-md-6 order-2 order-md-1 mb-2">
					<app-vertical-product-carousel
						[verticalHeight]="540"
						[thumbnailHeight]="110"
						[direction]="productListDirection"
						[imageList]="imageList"
						(select)="handleProductClicked($event)"></app-vertical-product-carousel>
				</div>
				<div class="col-24 col-md-18 order-1 order-md-2 mb-2 d-flex justify-content-around align-items-center position-relative">
					<button class="position-absolute w-25 h-100 top-50 start-0 translate-middle-y bg-transparent border-0 opacity-50" (click)="previousImage()">
						{{ leftArrow }}
					</button>
					<app-product-image
						*ngIf="!isSmallScreen()"
						[height]="432"
						[imageList]="imageList"
						[url]="currentImage"
						[enableZoom]="true"></app-product-image>
					<app-product-image
						*ngIf="isSmallScreen()"
						[height]="300"
						[imageList]="imageList"
						[url]="currentImage"
						[enableZoom]="true"></app-product-image>
					<button class="position-absolute w-25 h-100 top-50 end-0 translate-middle-y bg-transparent border-0 opacity-50" (click)="nextImage()">
						{{ rightArrow }}
					</button>
				</div>
				<div class="col-24 order-3">
					<hr />
				</div>
				<div class="d-flex justify-content-evenly order-4">
					<div class="text-center">
						<p>Valor Bruto</p>
						<h3 class="fw-semibold text-primary">{{ currentSku?.price | brl }}</h3>
					</div>
					<div class="text-center">
						<p>Quantidade Total</p>
						<h3 class="fw-semibold text-primary">{{ quantity }}</h3>
					</div>
					<div class="text-center">
						<p>Valor Total</p>
						<h3 class="fw-semibold text-success">{{ totalValue | brl }}</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xl-12 col-xxl-14 col-24">
			<core-group-box classList="mb-4" bodyClassList="p-0">
				<ng-template #header> Digitação da Grade </ng-template>
				<ng-template #body>
					<div class="overflow-auto scrollbar p-4" [style.height]="'505px'">
						<table class="table table-sm mb-0" aria-describedby="tabela de grade de produto">
							<thead>
								<tr>
									<th scope="col" class="w-100">Grade Aberta</th>
									@for (size of sizeList; track size) {
									<th scope="col" class="text-center">{{ size }}</th>
									}
								</tr>
							</thead>
							<tbody>
								@for (color of colorList; track color.code) {
								<tr *ngIf="hasColorStock(color.code)">
									<td class="fw-semibold">
										<div class="d-flex align-items-center" (click)="updateProductImage(color.code)">
											<app-color-sample [rgb]="color.rgb" size="lg" [sampleUrl]="color.sampleUrl" [disableSelection]="true">
											</app-color-sample>
											<div class="mx-2 my-1">
												<p class="m-0">{{ color.description }}</p>
												<p class="m-0">{{ color.code }}</p>
											</div>
										</div>
									</td>
									@for (size of sizeList; track size) {
									<td class="text-center">
										<input
											#quantity
											type="number"
											class="form-control text-center form-control-sm"
											[id]="getGradeName(color.code, size)"
											[style.width]="'50px'"
											[class.border-primary]="findValue(color.code, size)"
											(change)="changeQuantity($event, color.code, size)"
											(focus)="updateValueField(color.code, size)"
											(keyup)="changeQuantity($event, color.code, size)"
											[disabled]="!enableInputField(color.code, size)" />
										<small class="d-block">Estoque</small>
										<small class="d-block">{{ getStockQuantity(color.code, size) }}</small>
									</td>
									}
								</tr>
								}
							</tbody>
						</table>
					</div>
				</ng-template>
			</core-group-box>
			<div class="d-flex justify-content-end">
				<button class="btn btn-secondary me-2" (click)="cancelAction()">Cancelar</button>
				<div [ngbTooltip]="actionButtonTooltip">
					<button class="btn btn-primary" (click)="addOrUpdateCart()" [disabled]="!enableActionButton">
						{{ actionButtonLabel }}
					</button>
				</div>
			</div>
			<app-product-details-tab [product]="product"></app-product-details-tab>
		</div>
	</div>
</div>
