<div
	[class]="internalClassList"
	[style.height]="internalHeight"
>
	<app-product-image
		*ngFor="let imageUrl of imageList"
		[url]="imageUrl.url"
		[height]="thumbnailHeight"
		[width]="thumbnailWidth"
		(click)="onImageClick(imageUrl?.url)"
		(keypress)="onImageClick(imageUrl?.url)"
	></app-product-image>
</div>
