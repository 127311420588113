import { BOOTSTRAP_BREAKPOINT_MD, BOOTSTRAP_BREAKPOINT_SM } from '@lib-core/constants';

export function isSmBreakpoint(): boolean {
	return window.innerWidth < BOOTSTRAP_BREAKPOINT_SM;
}

export function isMdBreakpoint(): boolean {
	return window.innerWidth < BOOTSTRAP_BREAKPOINT_MD;
}

export function hasPageScrollY(): boolean {
	var scrollHeight = document.body.scrollHeight;
	var clientHeight = document.documentElement.clientHeight;
	return scrollHeight > clientHeight;
}
