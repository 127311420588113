import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { effect, Component, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { CpfCnpjPipe } from '@lib-core/pipes';
import { CustomerModel, RepresentativeModel } from '@lib-core/models';
import { CustomerStore, RepresentativeStore } from '@lib-core/stores';
import { LoaderService, CustomerService, ToastService } from '@lib-core/services';
import { getDataTableOptions, getDataTableOrderByIndex, getNotOrderableColumns } from '@lib-core/config';

@Component({
	standalone: true,
	selector: 'shared-customer-select-modal',
	imports: [CommonModule, CpfCnpjPipe, NgbModalModule, DataTablesModule],
	templateUrl: './customer-select-modal.component.html',
})
export class CustomerSelectModalComponent {
	customerList: CustomerModel[];
	representative: RepresentativeModel;

	dtOptions = {
		...getDataTableOptions(),
		...getNotOrderableColumns([3]),
		...getDataTableOrderByIndex(1),
		lengthChange: false,
	};

	@Output()
	customerSelected = new EventEmitter<CustomerModel>();

	constructor(
		private modalService: NgbModal,
		private toastService: ToastService,
		private customerStore: CustomerStore,
		private loaderService: LoaderService,
		private customerService: CustomerService,
		private representativeStore: RepresentativeStore,
	) {
		effect(() => {
			this.representative = this.representativeStore.get();
			this.queryCustomers();
		});
	}

	queryCustomers(): void {
		if (!this.representative?.code) {
			return;
		}
		this.loaderService.start();

		this.customerService.listCustomersByRepresentative(this.representative.code).subscribe({
			next: response => {
				this.customerList = null;

				if (response?.success) {
					this.customerList = response.data;
					return;
				}
				this.toastService.danger('Erro ao buscar clientes: ' + response?.message);
			},
			error: () => {
				this.toastService.danger('Erro ao buscar clientes, por favor tente novamente.');
				this.modalService.dismissAll();
			},
			complete: () => {
				setTimeout(() => {
					this.loaderService.stop();
				}, 500);
			},
		});
	}

	dismiss(): void {
		this.modalService.dismissAll();
	}

	select(customer: CustomerModel): void {
		this.customerStore.set(customer);
		this.customerSelected.emit(customer);
		this.modalService.dismissAll();
	}
}
