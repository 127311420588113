// COMPONENTE SERÁ EXCLUÍDO QUANDO VIRAR 100% DO PORTAL

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarService } from '@lib-core/services';
import { ENVIRONMENT } from '../../../../../../environments/environment';

@Component({
	standalone: true,
	selector: 'core-sidebar',
	imports: [CommonModule],
	styleUrl: './sidebar.component.scss',
	templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
	constructor(private sidebarService: SidebarService) {}

	get isVisible(): boolean {
		return this.sidebarService.isVisible;
	}

	get portalUrl(): string {
		return ENVIRONMENT.backOfficeSettings.portalUrl;
	}

	close(): void {
		this.sidebarService.close();
	}
}
