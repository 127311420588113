import { CommonModule } from '@angular/common';
import { Observable, map } from 'rxjs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, HostListener, QueryList, ViewChildren, effect } from '@angular/core';

import { APP_DEFAULT_ROUTE } from '@proj-b2b/app/router/app.routes';
import { BOOTSTRAP_BREAKPOINT_SM } from '@lib-core/constants';

import { DirectionType } from '@lib-core/enums';
import { GalleryResource } from '@lib-core/resources';
import { GroupBoxComponent } from '@lib-core/components';
import { CartItemRequestDto } from '@lib-core/dtos';
import { ColorSampleComponent } from '@proj-b2b/app/components/color-sample/color-sample.component';
import { ProductImageComponent } from '@proj-b2b/app/components/product-image/product-image.component';
import { ProductCarouselComponent } from '@proj-b2b/app/components/product-carousel/product-carousel.component';
import { ColorSampleListComponent } from '@proj-b2b/app/components/color-sample-list/color-sample-list.component';
import { CartService, RouterService, ToastService } from '@lib-core/services';
import { ProductDetailsTabComponent } from './product-details-tab/product-details-tab/product-details-tab.component';
import { CapitalizePipe, CurrencyBrlPipe } from '@lib-core/pipes';
import { VerticalProductCarouselComponent } from '@proj-b2b/app/modules/product/vertical-product-carousel/vertical-product-carousel.component';
import { leftArrowUnicode, rightArrowUnicode } from '@lib-core/constants/unicode-characters-contants';
import { CartStore, CustomerStore, ModalityStore, SalesTeamStore } from '@lib-core/stores';
import { capitalize, isObjectNullOrEmpty, isSmBreakpoint } from '@lib-core/helpers';
import { CartModel, ColorModel, ImageModel, ProductModel, ProductSkuModel } from '@lib-core/models';
import { CART_ROUTE_NAMES } from '@proj-b2b/app/modules/cart/cart.constants';

@Component({
	selector: 'app-product-details',
	standalone: true,
	templateUrl: './product-details.component.html',
	imports: [
		CommonModule,
		CapitalizePipe,
		CurrencyBrlPipe,
		NgbTooltipModule,
		GroupBoxComponent,
		ColorSampleComponent,
		ProductImageComponent,
		ProductCarouselComponent,
		ColorSampleListComponent,
		VerticalProductCarouselComponent,
		ProductDetailsTabComponent,
	],
})
export class ProductDetailsComponent implements AfterViewInit {
	cart: CartModel;
	product: ProductModel;
	currentSku: ProductSkuModel;
	currentImage: string;
	fieldUpdateTimeout: any;

	stockMap = new Map<string, ProductSkuModel>();
	quantityMap = new Map<string, number>();
	screenWidth: number;
	leftArrow = leftArrowUnicode;
	rightArrow = rightArrowUnicode;

	$productCode: Observable<string> = this.activatedRoute.params.pipe(map(p => p['id']));

	@ViewChildren('quantity')
	quantityInputList: QueryList<any>;

	constructor(
		private router: Router,
		private cartStore: CartStore,
		private cartService: CartService,
		private toastService: ToastService,
		private routerService: RouterService,
		private customerStore: CustomerStore,
		private modalityStore: ModalityStore,
		private activatedRoute: ActivatedRoute,
		private salesTeamStore: SalesTeamStore,
		private galleryResource: GalleryResource,
	) {
		this.screenWidth = window.innerWidth;
		effect(() => {
			this.cart = this.cartStore.get();
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.screenWidth = window.innerWidth;
	}

	ngAfterViewInit() {
		this.initialize();
	}

	initialize() {
		this.clear();
		this.$productCode.subscribe(productCode => {
			this.loadProduct(productCode);
		});
	}

	get isEditing(): boolean {
		return this.cart?.cartItemList?.some(item => item.productCode == this.product?.code);
	}

	get sizeList(): string[] {
		return this.product?.sizes?.map(size => size.size) ?? [];
	}

	get colorList(): ColorModel[] {
		return this.product?.colors ?? [];
	}

	get hasCustomerSelected(): boolean {
		return !!this.customerStore.get();
	}

	get enableActionButton(): boolean {
		return this.hasCustomerSelected && this.hasItems;
	}

	get actionButtonTooltip(): string {
		if (!this.hasCustomerSelected) {
			return 'Selecione um cliente primeiro';
		}
		if (!this.hasItems) {
			return 'Adicione pelo menos um item';
		}
		return '';
	}

	get imageList(): ImageModel[] {
		if (this.product?.imagesUrl && this.product.skus) {
			const filteredImages = this.product.imagesUrl.filter(image => this.product.skus.some(sku => sku.colorCode === image.color));
			return filteredImages;
		}
		return [];
	}

	get skuList(): ProductSkuModel[] {
		return this.product?.skus ?? [];
	}

	get quantity(): number {
		return this.quantityMap.size > 0 ? [...this.quantityMap.values()].reduce((a, b) => a + b, 0) : 0;
	}

	get title(): string {
		return `${this.product.description} (${this.product.code})`;
	}

	get actionButtonLabel(): string {
		return this.isEditing ? 'Salvar alterações' : 'Adicionar à sacola';
	}

	get totalValue(): number {
		let total = 0;

		this.stockMap.forEach((sku, grade) => {
			if (isObjectNullOrEmpty(sku)) return;
			const quantity = this.quantityMap.get(grade) || 0;
			total += sku.price * quantity;
		});

		return total;
	}

	get hasItems(): boolean {
		return this.quantity > 0;
	}

	get productListDirection() {
		return isSmBreakpoint() ? DirectionType.Horizontal : DirectionType.Vertical;
	}

	clear() {
		this.stockMap.clear();
		this.quantityMap.clear();
	}

	loadProduct(productCode: string) {
		const modalityCode = this.modalityStore.get()?.code;
		const salesTeamCode = this.salesTeamStore.get()?.code;
		const customerCode = this.customerStore.get()?.code;

		this.galleryResource.getProductDetails(productCode, modalityCode, salesTeamCode, customerCode).subscribe(response => {
			this.product = response.data;
			this.calculateStock();
			this.loadQuantities();
			this.loadInitialGrade();
		});
	}

	loadInitialGrade() {
		this.currentSku = this.product?.skus[0];
		const WhiteColorCode = '00001';

		this.colorList?.forEach(color => {
			const hasStock = this.hasColorStock(color.code);
			if (hasStock && !this.currentImage) {
				this.currentImage = this.imageList.find(img => img.corMostruario == 'true')?.url;
			}
			if (color.code === WhiteColorCode) {
				return;
			}
			if (this.currentImage) {
				return;
			}
		});
	}

	calculateStock() {
		this.colorList?.forEach(color => {
			this.sizeList?.forEach(size => {
				const sku = this.product?.skus.filter(sku => sku.colorCode === color.code && sku.sizeCode === size);
				this.stockMap.set(this.getGradeName(color.code, size), sku[0]);
			});
		});
	}

	loadQuantities() {
		if (this.isEditing) {
			this.cart.cartItemList.forEach(item => {
				if (item.productCode === this.product?.code) {
					var stock = this.getStockQuantity(item.colorCode, item.sizeCode);
					if (stock === 0) {
						item.quantity = null;
					}
					this.quantityMap.set(this.getGradeName(item.colorCode, item.sizeCode), item.quantity);
				}
			});
			this.updateFields();
		}
	}

	getGradeName(color: string, size: string): string {
		return `${color}-${size}`;
	}

	getStockQuantity(color: string, size: string): number {
		return this.stockMap.get(this.getGradeName(color, size))?.stock || 0;
	}

	getStock(color: string, size: string): ProductSkuModel {
		return this.stockMap.get(this.getGradeName(color, size));
	}

	hasColorStock(color: string): boolean {
		return this.sizeList.some(size => this.hasStock(color, size));
	}

	hasStock(color: string, size: string): boolean {
		return this.getStockQuantity(color, size) > 0;
	}

	enableInputField(color: string, size: string): boolean {
		return this.hasCustomerSelected && this.hasStock(color, size);
	}

	getColorLabel(color: ColorModel): string {
		return capitalize(`${color.code} ${color.description}`);
	}

	updateFields() {
		if (this.quantityInputList.length > 0) {
			this.quantityInputList.forEach(field => {
				const grade = field.nativeElement.id;
				const quantity = this.quantityMap.get(grade);
				const [color, size] = this.getColorAndSizeFromGrade(grade);
				const stock = this.getStockQuantity(color, size);

				if (quantity > stock) {
					this.quantityMap.set(grade, stock);
					field.nativeElement.value = stock;
					return;
				}

				field.nativeElement.value = quantity ?? '';
			});
			clearTimeout(this.fieldUpdateTimeout);
			return;
		}

		this.fieldUpdateTimeout = setTimeout(() => {
			this.updateFields();
		}, 100);
	}

	getColorAndSizeFromGrade(grade: string): string[] {
		return grade.split('-');
	}

	findValue(color: string, size: string): number {
		const grade = this.getGradeName(color, size);
		const stock = this.getStockQuantity(color, size);
		return stock > 0 ? this.quantityMap.get(grade) ?? 0 : 0;
	}

	changeQuantity(event: any, color: string, size: string) {
		const stock = this.getStockQuantity(color, size);
		const grade = this.getGradeName(color, size);
		const quantity = parseInt(event.target.value, 10);

		if (isNaN(quantity) || stock === 0 || quantity < 0) {
			this.quantityMap.delete(grade);
			event.target.value = '';
			this.updateValueField(color, size);
			return;
		}

		if (quantity > stock) {
			this.quantityMap.set(grade, stock);
			event.target.value = stock;
			this.updateValueField(color, size);
			return;
		}

		this.quantityMap.set(grade, quantity);
		this.updateValueField(color, size);
	}

	updateValueField(color: string, size: string) {
		const stock = this.getStock(color, size);
		this.currentSku = stock;
		this.currentImage = this.imageList.find(img => img?.color === color)?.url;
	}

	updateProductImage(color: string) {
		this.currentImage = this.imageList.find(img => img?.color === color)?.url;
	}

	addOrUpdateCart() {
		if (!this.enableActionButton) {
			return;
		}

		const items = [] as CartItemRequestDto[];

		this.quantityMap.forEach((quantity, grade) => {
			const [color, size] = this.getColorAndSizeFromGrade(grade);
			items.push({
				cartId: this.cart?.id ?? 0,
				quantity,
				sizeCode: size,
				colorCode: color,
				productCode: this.product.code,
			} as CartItemRequestDto);
		});

		if (items.length === 0) {
			return;
		}

		this.cartService.addItems(items).subscribe({
			next: response => {
				if (response.success) {
					this.goToHomeOrCart();
					return;
				}
				this.showErrorToast(response.message);
			},
			error: err => {
				this.showErrorToast(err);
			},
		});
	}

	showErrorToast(message: string) {
		this.toastService.danger(`Erro ao editar sua sacola: ${message}`);
	}

	goToHomeOrCart() {
		if (this.isEditing) {
			this.router.navigate([CART_ROUTE_NAMES.details.url]);
			return;
		}
		this.router.navigate([APP_DEFAULT_ROUTE]);
	}

	cancelAction() {
		this.routerService.goBack();
	}

	selectImage(image: ImageModel) {
		this.currentImage = image.url;
	}

	handleProductClicked(imageUrl: string) {
		this.currentImage = imageUrl;
	}

	currentImageIndex: number = 0;

	updateCurrentImage() {
		if (this.imageList.length > 0) {
			this.currentImage = this.imageList[this.currentImageIndex].url;
		}
	}

	nextImage() {
		this.currentImageIndex = (this.currentImageIndex + 1) % this.imageList.length;
		this.updateCurrentImage();
	}

	previousImage() {
		this.currentImageIndex = (this.currentImageIndex - 1 + this.imageList.length) % this.imageList.length;
		this.updateCurrentImage();
	}

	updateImageFromExternalAction(newImageUrl: string) {
		const newIndex = this.imageList.findIndex(image => image.url === newImageUrl);
		if (newIndex !== -1) {
			this.currentImageIndex = newIndex;
			this.updateCurrentImage();
		}
	}

	isSmallScreen(): boolean {
		return this.screenWidth < BOOTSTRAP_BREAKPOINT_SM;
	}
}
