<shared-page-title />
<div>
	<table *ngIf="ebookList" datatable [dtOptions]="dtOptions" aria-describedby="tabela-devoluções" class="table table-striped w-100">
		<thead>
			<tr>
				<th class="col-3 text-center" scope="col">Código</th>
				<th class="col-12 text-center" scope="col">Mensagem</th>
				<th class="col-3 text-center" scope="col">Status</th>
				<th class="col-4 text-center" scope="col">Data Criação</th>
				<th class="col-2 text-center" scope="col">Download</th>
			</tr>
		</thead>
		<tbody class="table-group-divider" *ngIf="ebookList.length > 0">
			@for (ebook of ebookList; track ebook.id) {
			<tr class="align-middle">
				<td class="text-center">{{ ebook.id | padLeft : 5 }}</td>
				<td>{{ ebook.message }}</td>
				<td class="text-center fw-semibold" [class]="getStatusClass(ebook)">
					{{ getStatusDescription(ebook) }}
				</td>
				<td class="text-center">{{ ebook.insertDate | longDate }}</td>
				<td class="text-center">
					<a class="btn btn-outline-primary" [href]="ebook.fileUrl" target="_blank" [class.disabled]="!canDownload(ebook)">
						<i class="bi bi-download me-1"></i>
						<span>Baixar</span>
					</a>
				</td>
			</tr>
			}
		</tbody>
	</table>
</div>
