import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ImageModel } from '@lib-core/models';
import { DirectionType } from '@lib-core/enums';
import { ProductImageComponent } from '@proj-b2b/app/components/product-image/product-image.component';

@Component({
	standalone: true,
	selector: 'app-vertical-product-carousel',
	templateUrl: './vertical-product-carousel.component.html',
	imports: [CommonModule, ProductImageComponent],
})
export class VerticalProductCarouselComponent {
	@Input()
	verticalHeight: string | number = 300;

	@Input()
	thumbnailHeight: string | number = 100;

	@Input()
	thumbnailWidth: string | number = 100;

	@Input()
	imageList: ImageModel[] = [];

	@Input()
	direction: DirectionType = DirectionType.Vertical;

	@Input()
	classList: string = '';

	@Output()
	select = new EventEmitter<string>();

	get componentVerticalHeight(): string {
		return typeof this.verticalHeight === 'string'
			? this.verticalHeight
			: `${this.verticalHeight}px`;
	}

	get componentThumbnailHeight(): string {
		return typeof this.thumbnailHeight === 'string'
			? this.thumbnailHeight
			: `${this.thumbnailHeight}px`;
	}

	get directionClass(): string {
		return this.direction === DirectionType.Vertical
			? 'flex-column overflow-x-hidden flex-row overflow-y-always'
			: 'flex-row overflow-y-hidden flex-row overflow-x-always';
	}

	get internalClassList(): string {
		return `d-flex gap-2 ${this.directionClass} ${this.classList}`;
	}

	get internalHeight(): string {
		return this.direction === DirectionType.Vertical
			? this.componentVerticalHeight
			: this.componentThumbnailHeight;
	}

	onImageClick(imageUrl: string) {
		this.select.emit(imageUrl);
	}
}
