<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	<li [ngbNavItem]="1">
		<button ngbNavLink>Detalhes do produto</button>
		<ng-template ngbNavContent>
			<div class="p-2">
				<p><strong>Categoria: </strong> {{ product.categoryDescription }}</p>
				<p><strong>Segmento: </strong> {{ product.segmentDescription }}</p>
				<p><strong>Espécie: </strong> {{ product.specieDescription }}</p>
				<p><strong>Gênero: </strong> {{ product.genderDescription }}</p>
				<p><strong>Grupo produto: </strong> {{ product.productGroupDescription }}</p>
				<p><strong>Destino: </strong> {{ product.recipientDescription }}</p>
			</div>
		</ng-template>
	</li>

	<li [ngbNavItem]="2">
		<button ngbNavLink>Tabela de medidas</button>
		<ng-template ngbNavContent>
			<div>
				<img src="{{ getMeasureTableImage() }}" alt="" />
				<div class="p-2">
					<p *ngIf="getMeasureTableImage() == null"><strong>Erro: </strong> Nenhuma tabela de medida encontrada para o produto especificado</p>
				</div>
			</div>
		</ng-template>
	</li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
